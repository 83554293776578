import axios from 'axios'
import { defineStore } from 'pinia'


export const useAuthStore = defineStore({
    id: 'auth',


    state: () => ({
        user: null,
        errorToken: '',
        error: ''
    }),
    actions: {
        login(user) {
            return new Promise((resolve, reject) => {
                axios.post("auth/", user)
                    .then(res => {
                        this.user = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        if (err.response) {
                            this.error = err.response.data
                        }
                        reject(err)
                    })
            })
        }
    },

    getters:{
        isAuthenticated: state => {
            return !!state.user
        },
        userGetters: state => {
            return state.user
        },
        errorGetters: state => {
            return state.error
        },
        errorTokenGetters: state => {
            return state.errorToken
        }
    }
})


