import axios from 'axios'
import { defineStore } from 'pinia'
import {useAuthStore} from "@/stores/auth";


export const useNoteStore = defineStore({
    id: 'notes',


    state: () => ({
        notes: null,
        notesWithPageSize: null,
        maxPage: 0,
        lastNote: null,
        showLastNote: null,
        intervalNote: 0,
        note: null
    }),
    actions: {
        getNotes(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().user.token
                    }
                }

                let url = new URL(axios.defaults.baseURL +'api/notes/')
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i=0;i< Object.keys(payload.queryParams).length; i++){
                        params.set(Object.keys(payload.queryParams)[i],Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.notes = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.notes = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                }
            })
        },
        getNotesWithPageSize(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().user.token
                    }
                }

                let url = new URL(axios.defaults.baseURL +'api/notes/')
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i=0;i< Object.keys(payload.queryParams).length; i++){
                        params.set(Object.keys(payload.queryParams)[i],Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.notesWithPageSize = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.notesWithPageSize = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            reject(err)
                        })
                }
            })
        },
        getNote(id){
            return new Promise((resolve,reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().user.token
                    }
                }
                axios.get('api/notes/' + id + '/', config)
                    .then(res => {

                        this.note = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        editNote(payload){
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().user.token
                    }
                }
                axios.patch('api/notes/' + payload.id + '/', {name: payload.name},config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
    },

    getters:{

        noteGetters: state => {
            return state.note
        },
        notesWithPageSizeGetters: state => {
            return state.notesWithPageSize
        },
        lastNoteGetters: state => {
            return state.lastNote
        },
        showLastNoteGetters: state => {
            return state.showLastNote
        },
        maxPageGetters: state => {
            return state.maxPage
        },
        intervalNoteGetters: state => {
            return state.intervalNote
        },
        notesGetters: state => {
            return state.notes
        }
    }
})


