import axios from 'axios'
import { defineStore } from 'pinia'
import {useAuthStore} from "@/stores/auth";


export const useApiSettingStore = defineStore({
    id: 'apiSettings',

    state: () => ({
        apiSettings: null,
        error500: '',
        intervalUpdateListNote: 0,
        errorNetwork: false
    }),
    actions: {
        getApiSettings(){
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().user.token
                    }
                }
                axios.options('api/',config)
                    .then(res => {
                        this.apiSettings = res.data
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    },

    getters:{
        apiSettingsGetters: state => {
            return state.apiSettings
        },
        error500Getters: state => {
            return state.error500
        },
        errorNetworkGetters: state => {
            return state.errorNetwork
        },
        intervalUpdateListNoteGetters: state => {
            return state.intervalUpdateListNote
        }
    }
})


