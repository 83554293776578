import { createRouter, createWebHistory } from 'vue-router'
import {useAuthStore} from "@/stores/auth";

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "HomeView" */ '../views/HomeView')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      hideForAuth: true
    },
    component: () => import(/* webpackChunkName: "LoginView" */ '../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      hideForAuth: true
    },
    component: () => import(/* webpackChunkName: "RegisterView" */ '../views/RegisterView.vue')
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      hideForAuth: true
    },
    component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/ResetPassword.vue')
  },
  {
    path: '/accountants',
    name: 'accountants',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "AccountantsView" */ '../views/AccountantsView.vue')
  },
  {
    path: '/user/:id',
    name: 'detailUser',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "DetailUser" */ '../views/DetailUser.vue')
  },
  {
    path: '/profile/:id',
    name: 'profile',
    meta: {
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "ProfileView" */ '../views/ProfileView.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/",
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (useAuthStore().isAuthenticated) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.hideForAuth)) {
    if (useAuthStore().isAuthenticated) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});



export default router
