import { createApp } from 'vue'
import App from './App.vue'

import BootstrapVue3 from 'bootstrap-vue-3'

import './assets/css/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import { createPinia } from 'pinia'
import { createPersistedStatePlugin } from 'pinia-plugin-persistedstate-2'

import axios from "axios";

import router from './router'
// import store from './store'
import mitt from 'mitt';
import moment from "moment";
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";

moment.locale('it')

const emitter = mitt();

axios.defaults.baseURL = 'https://api.toolni.bisanzioconsulting.it/'


const pinia = createPinia()

axios.interceptors.response.use(undefined, function (error) {
    if (error.response){
        if (error.response.status === 401) {
            useAuthStore().$reset()
            let string_split
            Object.keys(error.response.data).forEach(el => {
                string_split = el.split('-')
                if (string_split[1] === 'expired_token') {
                    useAuthStore().errorToken = 'Sessione scaduta'
                } else if (string_split[1] === 'invalid_token') {
                    useAuthStore().errorToken = 'Rieffettuare il login'
                } else if (el === 'code-invalid_code') {
                    useAuthStore().errorToken = error.response.data['code-invalid_code']
                } else if (el === 'authorization-user_inactive') {
                    useAuthStore().errorToken = error.response.data['authorization-user_inactive']
                }
            })
            router.push('/login').catch(() => {
            })
            return Promise.reject(error);
        }
    }
    if (error.message === 'Network Error') {
        useApiSettingStore().errorNetwork = true
    }
    return Promise.reject(error);
})


const app = createApp(App)
app.config.globalProperties.emitter = emitter
app.use(router)
app.use(BootstrapVue3)
app.use(pinia)
pinia.use(createPersistedStatePlugin())
app.mount('#app')