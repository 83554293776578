import axios from 'axios'
import {defineStore} from 'pinia'
import {useAuthStore} from "@/stores/auth";
import {useApiSettingStore} from "@/stores/apiSettings";
import {useNoteStore} from "@/stores/notes";


export const useTaskStore = defineStore({
    id: 'tasks',


    state: () => ({
        tasks: null,
        statusTask: '',
        intervalTask: '',
        lastTaskId: '',
        lastTask: null,
        errorTask: null
    }),
    actions: {
        retrieveTask() {
            return new Promise((resolve, reject) => {

                let firstObj
                this.intervalTask = setInterval(() => {
                    useTaskStore().getTasks({
                        queryParams: {
                            id: this.lastTaskId
                        }
                    })
                        .then(res => {
                            if (res.data.results[0]) {
                                this.lastTask = res.data.results[0]
                                if (res.data.results[0].success) {
                                    if (res.data.results[0].result.success) {

                                        let queryParams = {
                                            custom_user: useAuthStore().user.user_id,
                                            page_size: 1
                                        }

                                        useNoteStore().getNotesWithPageSize({queryParams: queryParams})
                                            .then(res => {
                                                firstObj = res.data.results[0]
                                                if (firstObj) {
                                                    if (firstObj.custom_user.id === useAuthStore().user.user_id) {
                                                        // if (firstObj.status !== 'r') {
                                                        useNoteStore().lastNote = res.data.results[0]
                                                        useNoteStore().showLastNote = true
                                                        useNoteStore().getNotes({queryParams: null})
                                                            .then(resp => {
                                                                let maxPage = Math.ceil(resp.data.count / useApiSettingStore().apiSettings.pagination['page_size'])
                                                                useNoteStore().maxPage = maxPage
                                                                useTaskStore().statusTask = 'finished'
                                                                this.lastTaskId = ''
                                                                clearInterval(useTaskStore().intervalTaskGetters)
                                                                resolve(resp)
                                                            })
                                                            .catch(() => {
                                                            })

                                                        // }
                                                    }
                                                }
                                            })
                                            .catch(err => {
                                                reject(err)
                                            })
                                    } else {
                                        useTaskStore().statusTask = 'failed'
                                        useTaskStore().lastTaskId = ''
                                        clearInterval(useTaskStore().intervalTaskGetters)
                                    }
                                } else {
                                    useTaskStore().lastTaskId = ''
                                    useTaskStore().statusTask = 'failed'
                                    useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                                    clearInterval(useTaskStore().intervalTaskGetters)
                                }
                            }
                        })
                        .catch(err => {
                            reject(err)
                        })


                }, 5000)
            })
        },
        getTasks(payload) {
            return new Promise((resolve, reject) => {
                const config = {
                    headers: {
                        Authorization: 'Token ' + useAuthStore().userGetters.token
                    }
                }

                let url = new URL(axios.defaults.baseURL + 'api/tasks/')
                if (payload.queryParams !== null) {
                    let params = new URLSearchParams()
                    for (let i = 0; i < Object.keys(payload.queryParams).length; i++) {
                        params.set(Object.keys(payload.queryParams)[i], Object.values(payload.queryParams)[i])
                    }
                    url.search = params
                    axios.get(url.pathname + url.search, config)
                        .then(res => {
                            this.tasks = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                } else {
                    axios.get(url.pathname, config)
                        .then(res => {
                            this.tasks = res.data
                            resolve(res)
                        })
                        .catch(err => {
                            if (err.response && err.response.status === 500) {
                                useApiSettingStore().error500 = 'Qualcosa non ha funzionato, riprova'
                            }
                            reject(err)
                        })
                }
            })
        }
    },

    getters: {
        tasksGetters: state => {
            return state.tasks
        },
        statusTaskGetters: state => {
            return state.statusTask
        },
        lastTaskIdGetters: state => {
            return state.lastTaskId
        },
        lastTaskGetters: state => {
            return state.lastTask
        },
        intervalTaskGetters: state => {
            return state.intervalTask
        },
        errorTaskGetters: state => {
            return state.errorTask
        }
    }
})


