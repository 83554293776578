<template>
  <div id="app">
    <router-view></router-view>
    <b-alert variant="danger" :show="store.errorNetworkGetters">Connessione assente</b-alert>
    <b-alert @click="goToHome" variant="success"
             :show="storeTask.statusTaskGetters === 'finished'"
    >Generazione Nota Integrativa Completata</b-alert>

    <b-alert @click="goToHome" class="prova" variant="danger"
             :show="storeTask.statusTaskGetters === 'failed'"
    >Qualcosa è andato storto</b-alert>
  </div>
</template>

<script>
import {useApiSettingStore} from "@/stores/apiSettings";
import {useTaskStore} from "@/stores/tasks";
import router from "@/router";

export default {
  setup(){

    let store = useApiSettingStore()
    let storeTask = useTaskStore()


    function goToHome(){
      useTaskStore().statusTask = ''
      router.push('/').catch(() => {})
    }

    return{
      goToHome,
      store,
      storeTask
    }
  },
  data(){
    return{
    }
  },
  watch:{
  },
  computed:{
    statusTask(){
      return useTaskStore().statusTaskGetters
    }
  },
  created() {
    if ((window.innerWidth < 767.98) || (screen.orientation.angle > 0)){
      this.changeVariables()
    } else {
      this.resetVariables()
    }
  },
  beforeUnmount() {
    useApiSettingStore().errorNetwork = false
    clearInterval(useApiSettingStore().intervalUpdateListNoteGetters)
    clearInterval(useTaskStore().intervalTaskGetters)
  },

  mounted() {
    if (useTaskStore().lastTaskIdGetters !== '') {
      useTaskStore().statusTask = 'creating'
      useTaskStore().retrieveTask()
    }

    window.addEventListener('resize', (e) => {
      if ((e.target.innerWidth < 767.98) || (screen.orientation.angle > 0)){
        this.changeVariables()
      } else {
        this.resetVariables()
      }
    })
  },

  methods:{
    changeVariables(){
      document.documentElement.style.setProperty('--x-small', '1.4vmax');
      document.documentElement.style.setProperty('--small', '1.9vmax');
      document.documentElement.style.setProperty('--medium', '2.8vmax');
      document.documentElement.style.setProperty('--large', '3.8vmax');
      document.documentElement.style.setProperty('--x-large', '4.6vmax');
      document.documentElement.style.setProperty('--xx-large', '7vmax');

      document.documentElement.style.setProperty('--width-img-logo', '2.5vmax');
      document.documentElement.style.setProperty('--width-img-circle', '3.5vmax');
      document.documentElement.style.setProperty('--height-img-logo', '3.5vmax');


      document.documentElement.style.setProperty('--line-height-small', '1.6vmax');
      document.documentElement.style.setProperty('--line-height-medium', '2.1vmax');
      document.documentElement.style.setProperty('--line-height-large', '2.3vmax');
      document.documentElement.style.setProperty('--line-height-x-large', '3.1vmax');
      document.documentElement.style.setProperty('--line-height-xx-large', '5.8vmax');
      document.documentElement.style.setProperty('--line-height-xxx-large', '8.4vmax');
    },
    resetVariables(){
      document.documentElement.style.setProperty('--x-small', '1.4vmin');
      document.documentElement.style.setProperty('--small', '1.9vmin');
      document.documentElement.style.setProperty('--medium', '2.8vmin');
      document.documentElement.style.setProperty('--large', '3.8vmin');
      document.documentElement.style.setProperty('--x-large', '4.6vmin');
      document.documentElement.style.setProperty('--xx-large', '7vmin');

      document.documentElement.style.setProperty('--width-img-logo', '2.5vmin');
      document.documentElement.style.setProperty('--width-img-circle', '3.5vmin');
      document.documentElement.style.setProperty('--height-img-logo', '3.5vmin');

      document.documentElement.style.setProperty('--line-height-small', '1.6vvmin');
      document.documentElement.style.setProperty('--line-height-medium', '2.1vvmin');
      document.documentElement.style.setProperty('--line-height-large', '2.3vvmin');
      document.documentElement.style.setProperty('--line-height-x-large', '3.1vvmin');
      document.documentElement.style.setProperty('--line-height-xx-large', '5.8vvmin');
      document.documentElement.style.setProperty('--line-height-xxx-large', '8.4vvmin');
    },
  }
}
</script>

<style>
.alert-success.alert,.prova.alert-danger.alert{
  left: 0!important;
  right: unset!important;
  position: fixed!important;
  margin-left: 30px;
  margin-bottom: 30px;
  cursor: pointer;
  bottom: 0;
}
.alert-danger.alert{
  position: fixed!important;
  z-index: 10;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px;
}
[v-cloak] {
  display: none;
}
#app {
  min-height: 100vh;
  background: url("./assets/sfondo/sfondo-app.svg");
}
</style>
